import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

/**
 * Validator checks specific values for registration email
 */
export function emailExceptValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const valueTrimmed = control.value.trim();

    const isInvalid = /@.*test.*$/.test(valueTrimmed) && !/@ritest.es$/.test(valueTrimmed);

    return isInvalid ? { except: true } : null;
  }
}
