import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ConstantFormConstants} from '../../constants/constant-form-constants';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UrlService} from '../../services/url/url.service';
import {TranslateService} from '@ngx-translate/core';
import {ResetPasswordRequest} from './interfaces/reset-password-request';
import {ResetPasswordService} from './reset-password.service';
import {RecaptchaComponent} from 'ng-recaptcha';
import {trimmedValidator} from '../../validators/trimmed.validator';
import {MixpanelService} from "../../services/mixpanel/mixpanel.service";
import {EnvironmentService} from '../../services/env/environment.service';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.pug',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  @ViewChild('invisible')
  private invisibleRecaptcha: RecaptchaComponent;

  emailPattern: RegExp;

  resetPasswordForm: FormGroup;

  requestSent = false;

  formError = '';

  useCaptcha: boolean;

  constructor(private formConstants: ConstantFormConstants,
              private urlService: UrlService,
              private translate: TranslateService,
              private resetPasswordService: ResetPasswordService,
              private mixpanelService: MixpanelService,
              private environmentService: EnvironmentService,
              @Inject(DOCUMENT) private document: Document) {
    this.emailPattern = formConstants.EMAIL_PATTERN;
    this.useCaptcha = !this.environmentService.config.enableAutomation;
  }

  ngOnInit(): void {
    this.setResetPasswordFormGroup();
    this.mixpanelService.track("Back office page visit", {"Property": "Reset password?"})
  }

  /**
   * Sets page form group
   */
  setResetPasswordFormGroup() {
    this.resetPasswordForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.maxLength(96),
        trimmedValidator(this.emailPattern, true)
      ])
    });
  }

  /**
   * Go to Sign In page
   */
  goSignInForm() {
    this.urlService.navigateToState('login');

    return false;
  }

  /**
   * Submit form
   */
  submitResetPassword(captchaResponse = "") {    
    if (this.useCaptcha && !captchaResponse) {
      return;
    }

    this.formError = '';

    const data: ResetPasswordRequest = {
      email: this.resetPasswordForm.get('email').value.trim(),
      lang: this.translate.currentLang,
      recaptchaResponse: captchaResponse
    };

    this.resetPasswordService.resetPassword(data).subscribe({
      next: (response) => {
        switch (response.result) {
          case 'ok':
            this.mixpanelService.track("Back office page visit", {"Property": "Reset password email sent"});
            this.requestSent = true;
            break;
          case 'recaptcha_not_verified':
            this.formError = 'RECAPTCHA_NOT_VERIFIED';
            this.invisibleRecaptcha.reset();
            break;
          case 'user_exists_in_salt':
            const backofficePath = this.environmentService.getPathForBackOfficeRequests() + '/saltauth/' + data.email;
            this.document.location.assign(backofficePath)
            break;
          default:
            this.formError = 'UNEXPECTED_ERROR';
            this.invisibleRecaptcha.reset();
        }
      },
      error: (err) => {
        this.formError = 'UNEXPECTED_ERROR';
        this.invisibleRecaptcha.reset();
      }
    });
  }

  /**
   * Execute captcha
   */
  executeCaptcha() {
    if (this.useCaptcha) {
        this.invisibleRecaptcha.execute();
    }
    else {
        this.submitResetPassword();
    }
  }

}
